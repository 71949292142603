<template>
  <div
    class="d-flex justify-content-center align-items-center flex-column payment-success text-center"
  >
    <h1>
      {{ $t("Redirecting....") }}
    </h1>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    paymentSuccessLocale() {
      return this.$t("payments.success");
    },
    ...mapGetters("auth", ["user"]),
  },
  created() {
    console.log("user", this.user);
    let route = this.getRedirects(this.user);
    console.log("route", route);
  },
  methods: {
    getRedirects(user) {

      if (user.expat == false) {
        if (!user.profession) {
          return "/user/start/1";
        }

        if (user.totalPersons == 2 && !user.tenant.profession) {
          return "/user/income/tenant/1";
        }

        if (!user.paid) {
          return "/user/start/2";
        }

        if (!user.phone_number_verified_at) {
          return "/user/flow/1";
        }

        if (!user.country) {
          return "/user/flow/3";
        }

        if (!user.dob) {
          return "/user/flow/4";
        }

        if (!user.veriff_id) {
          return "/user/flow/5";
        }

        if (!user.nordigen_account_id) {
          return "/user/flow/7";
        }

        if (user.totalPersons == 1) {
          if (user.tenant.dob) {
            return "/user/flow/4/tenant";
          }
          if (!user.tenant.veriff_id) {
            return "/user/flow/5";
          }

          if (!user.tenant.nordigen_account_id) {
            return "/user/flow/7";
          }
        }
      }

      //   if (!data.user.expat && !data.user.phone_number_verified_at) {
      //     window.location.href = "/user/flow/1";
      //     return;
      //   }

      //   if (!data.user.paid) {
      //     window.location.href = "/user/start/1";
      //     return;
      //   }
    },
  },
};
</script>

<style lang="scss">
body {
  background-color: #ffff !important;
}

.payment-success {
  height: calc(100vh - 50px);
}
</style>
